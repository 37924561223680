import { HttpClient } from 'aurelia-fetch-client';

import {
    CommentPaginatedResponse,
    CommentCreatedResponse,
    CommentModel,
    CommentPayload,
} from 'schema/comment/comment-schema';
import { CaseDetails } from 'schema/case/case-details-schema';
import { CaseAlertQueryParams, CaseAlertResponse } from 'schema/case/case-details-alert-schema';
import { DocumentPaginatedResponse, DocumentCreatedResponse, DocumentModel } from 'schema/document/document-schema';
import { emptyPaginatedResult } from './utils/empty-paginated-result';
import { urlParamsBuilder } from 'utils/url-params-builder';
import {
    TransactionHistoryPeriod,
    TransactionHistoryResponse,
    TransactionListParams,
    TransactionListReponse,
} from 'schema/transaction/transaction-schema';

export type UpdatePayload = {
    name: string;
    description: string;
    comment: string;
};
export type CaseCommentPayload = CommentPayload & {
    caseId: string;
};
export type CaseAssignPayload = {
    caseId: string;
    userIds: string[];
};
export type CaseAlertPayload = {
    alertId: number;
};

export type CaseApi = {
    getById(id: string): Promise<CaseDetails | null>;
    update(caseId: string, payload: UpdatePayload): Promise<void>;
    getComments(id: string): Promise<CommentPaginatedResponse>;
    addComment(payload: CaseCommentPayload): Promise<CommentCreatedResponse>;
    removeComment(payload: CaseCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
    editComment(payload: CaseCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
    replyToComment(payload: CaseCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
    assign(payload: CaseAssignPayload): Promise<void>;
    getAlerts(caseId: string, payload: CaseAlertQueryParams): Promise<CaseAlertResponse>;
    addAlert(caseId: string, payload: CaseAlertPayload): Promise<void>;
    removeAlert(caseId: string, payload: CaseAlertPayload): Promise<void>;
    getDocuments(caseId: string): Promise<DocumentPaginatedResponse>;
    downloadDocument(caseId: string, documentId: DocumentModel['documentId']): Promise<any>;
    removeDocument(caseId: string, documentId: DocumentModel['documentId']): Promise<Pick<DocumentModel, 'documentId'>>;
    uploadDocument(caseId: string, formData: FormData): Promise<DocumentCreatedResponse>;
};

export const createCaseApi = (httpClient: HttpClient): CaseApi => ({
    getById: async (caseId: string) => {
        try {
            const response = await httpClient.get(`cases/${caseId}`);

            if (!response.ok) {
                throw new Error(`Could not get case with caseId: ${caseId}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    update: async (caseId: string, payload: UpdatePayload) => {
        try {
            const body = JSON.stringify(payload);
            const response = await httpClient.put(`cases/${caseId}`, body);

            if (!response.ok) {
                throw new Error(`Could not update case with caseId: ${caseId}`);
            }
        } catch (error) {
            console.error(error);
        }
    },
    getComments: async (caseId: string) => {
        try {
            const response = await httpClient.get(`cases/${caseId}/comments`);

            if (!response.ok) {
                throw new Error(`Could not get comments for case with caseId: ${caseId}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    addComment: async (payload: CaseCommentPayload) => {
        try {
            const { caseId, comment } = payload;
            const response = await httpClient.post(`cases/${caseId}/comments`, JSON.stringify({ comment }));

            if (!response.ok) {
                throw new Error(`Could not add comment for case with caseId: ${caseId} with payload: ${comment}`);
            }

            const data = await response.json();
            return { commentId: data.commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    removeComment: async (payload: CaseCommentPayload) => {
        try {
            const { caseId, commentId } = payload;
            const response = await httpClient.delete(`cases/${caseId}/comments/${commentId}`);

            if (!response.ok) {
                throw new Error(
                    `Could not delete comment with commentId: ${commentId} for case with caseId: ${caseId}`,
                );
            }

            return { commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    editComment: async (payload: CaseCommentPayload) => {
        try {
            const { comment, commentId, caseId } = payload;
            const response = await httpClient.put(`cases/${caseId}/comments/${commentId}`, JSON.stringify({ comment }));

            if (!response.ok) {
                throw new Error(`Could not edit comment with commentId: ${commentId} for case with caseId: ${caseId}`);
            }

            return { commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    replyToComment: async (payload: CaseCommentPayload) => {
        try {
            const { caseId, comment, parentId } = payload;
            const response = await httpClient.post(`cases/${caseId}/comments`, JSON.stringify({ parentId, comment }));

            if (!response.ok) {
                throw new Error(
                    `Could not reply to comment with commentId: ${parentId} for case with caseId: ${caseId}`,
                );
            }

            const data = await response.json();

            return { commentId: data.commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    assign: async (payload: CaseAssignPayload) => {
        const { caseId, userIds } = payload;
        const response = await httpClient.put(`cases/${caseId}/assign`, JSON.stringify({ userIds }));

        if (!response.ok) {
            throw new Error(`Could not assign users`);
        }
    },
    getAlerts: async (caseId: string, payload: CaseAlertQueryParams) => {
        const query = urlParamsBuilder(payload);
        const response = await httpClient.get(`cases/${caseId}/alerts/attached?${query}`);

        if (!response.ok) {
            throw new Error(`Could not get alerts for case with caseId: ${caseId}`);
        }

        return await response.json();
    },
    addAlert: async (caseId: string, payload: CaseAlertPayload) => {
        const body = JSON.stringify({ alertId: payload.alertId });
        const response = await httpClient.post(`cases/${caseId}/alerts`, body);

        if (!response.ok) {
            throw new Error(`Could not add alert with alertId: ${payload.alertId} for case with caseId: ${caseId}`);
        }
    },
    removeAlert: async (caseId: string, payload: CaseAlertPayload) => {
        const response = await httpClient.delete(`cases/${caseId}/alerts/${payload.alertId}`);

        if (!response.ok) {
            throw new Error(`Could not add alert with alertId: ${payload.alertId} for case with caseId: ${caseId}`);
        }
    },
    getDocuments: async (caseId: string) => {
        try {
            const response = await httpClient.get(`cases/${caseId}/documents`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get documents for case with caseId: ${caseId}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    downloadDocument: async (caseId: string, documentId: DocumentModel['documentId']) => {
        try {
            const response = await httpClient.get(`cases/${caseId}/documents/${documentId}`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get document with documentId: ${documentId}`,
                );
            }

            const data = await response.blob();
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    removeDocument: async (caseId: string, documentId: DocumentModel['documentId']) => {
        try {
            const response = await httpClient.delete(`cases/${caseId}/documents/${documentId}`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not delete document with documentId: ${documentId}`,
                );
            }

            return { documentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    uploadDocument: async (caseId: string, formdata: FormData) => {
        try {
            const response = await httpClient.post(`cases/${caseId}/documents`, formdata);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not upload document for case with caseId: ${caseId}`,
                );
            }

            const data = await response.json();
            return {
                documentId: data.documentId,
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
});
