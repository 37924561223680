import { HttpClient } from 'aurelia-fetch-client';
import { emptyPaginatedResult } from './utils/empty-paginated-result';
import {
    TransactionHistoryPeriod,
    TransactionHistoryResponse,
    TransactionListParams,
    TransactionListReponse,
} from 'schema/transaction/transaction-schema';

export type TransactionApi = {
    getTransactionsForPeriod(id: string, period: TransactionHistoryPeriod): Promise<TransactionHistoryResponse>;
    getTransactions(id: string, params?: TransactionListParams): Promise<TransactionListReponse>;
    getTransactionsFromTo(id: string, params: TransactionListParams): Promise<TransactionHistoryResponse>;
};

export const createTransactionApi = (httpClient: HttpClient): TransactionApi => ({
    getTransactionsForPeriod: async (customerId: string, period: TransactionHistoryPeriod) => {
        try {
            const response = await httpClient.get(`customers/${customerId}/transactions/period/${period}`);

            if (!response.ok) {
                throw new Error(
                    `Could not get transaction history for customer with id: ${customerId} and period: ${period}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
    getTransactions: async (customerId: string, params?: TransactionListParams) => {
        try {
            const { from, to, skip } = params;

            const _from = from.toISOString();
            const _to = to.toISOString();
            const _skip = skip ?? 0;

            const response = await httpClient.get(
                `customers/${customerId}/transactions/list?from=${_from}&to=${_to}&skip=${_skip}`,
            );

            if (!response.ok) {
                throw new Error(`Could not get transaction list for customer with id: ${customerId}`);
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    getTransactionsFromTo: async (customerId: string, params: TransactionListParams) => {
        try {
            const { from, to } = params;
            const _from = from.toISOString();
            const _to = to.toISOString();

            const response = await httpClient.get(`customers/${customerId}/transactions/bars?from=${_from}&to=${_to}`);

            if (!response.ok) {
                throw new Error(`Could not get transaction list for customer with id: ${customerId}`);
            }

            const data = await response.json();
            return data;
        } catch {
            return [];
        }
    },
});
