import { HttpClient } from 'aurelia-fetch-client';

import {
    CommentPaginatedResponse,
    CommentCreatedResponse,
    CommentModel,
    CommentPayload,
} from 'schema/comment/comment-schema';
import { CustomerAlertParams, CustomerAlertResponse } from 'schema/customer-alert-schema';
import { CustomerCaseParams, CustomerCaseResponse } from 'schema/customer-case-schema';
import { CustomerDetailsMapped, CustomerStatistics, CustomerDetailsResponse } from 'schema/customer-details-schema';
import { CustomerCompactParams, CustomerCompactPaginatedResponse } from 'schema/customer-schema';
import { DocumentPaginatedResponse, DocumentCreatedResponse, DocumentModel } from 'schema/document/document-schema';
import { nameFormatter } from 'utils/name-formatter';
import { urlParamsBuilder } from 'utils/url-params-builder';
import { emptyPaginatedResult } from './utils/empty-paginated-result';

export type CustomerCommentPayload = CommentPayload & {
    customerId: string;
};

export type CustomerApi = {
    getAll(params: CustomerCompactParams): Promise<CustomerCompactPaginatedResponse>;
    getById(id: string): Promise<CustomerDetailsMapped | null>;
    getStatsForId(id: string): Promise<CustomerStatistics | null>;
    getCasesForId(id: string, params: CustomerCaseParams): Promise<CustomerCaseResponse>;
    getAlertsForId(id: string, params: CustomerAlertParams): Promise<CustomerAlertResponse>;
    getDocuments(id: string): Promise<DocumentPaginatedResponse>;
    uploadDocument(customerId: string, formData: FormData): Promise<DocumentCreatedResponse>;
    downloadDocumentById(customerId: string, documentId: DocumentModel['documentId']): Promise<any>;
    removeDocument(
        customerId: string,
        documentId: DocumentModel['documentId'],
    ): Promise<Pick<DocumentModel, 'documentId'>>;
    getCommentsForId(id: string): Promise<CommentPaginatedResponse>;
    addComment(payload: CustomerCommentPayload): Promise<CommentCreatedResponse>;
    removeComment(payload: CustomerCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
    editComment(payload: CustomerCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
    replyToComment(payload: CustomerCommentPayload): Promise<Pick<CommentModel, 'commentId'>>;
};

export const createCustomerApi = (httpClient: HttpClient): CustomerApi => ({
    getAll: async (params: CustomerCompactParams) => {
        try {
            const query = urlParamsBuilder(params);
            const response = await httpClient.get(`customers?${query}`);

            if (!response.ok) {
                throw new Error(`Response status: ${response.status} - Could not get customers`);
            }

            return await response.json();
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    getById: async (id: string) => {
        try {
            const response = await httpClient.get(`customers/${id}`);

            if (!response.ok) {
                throw new Error(`Response status: ${response.status} - Could not get customer with id: ${id}`);
            }

            const data: CustomerDetailsResponse = await response.json();
            const mapped: CustomerDetailsMapped = {
                ...data
            };
            return mapped;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getStatsForId: async (id: string) => {
        try {
            const response = await httpClient.get(`customers/${id}/stats`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get statistics for customer with id: ${id}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getCasesForId: async (id: string, params: CustomerCaseParams) => {
        try {
            const query = urlParamsBuilder(params);
            const response = await httpClient.get(`customers/${id}/cases?${query}`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get cases for customer with id: ${id}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    getAlertsForId: async (id: string, params: CustomerAlertParams) => {
        try {
            const query = urlParamsBuilder(params);
            const response = await httpClient.get(`customers/${id}/alerts?${query}`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get cases for customer with id: ${id}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    getDocuments: async (id: string) => {
        try {
            const response = await httpClient.get(`customers/${id}/documents`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not get documents for customer with id: ${id}`,
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    uploadDocument: async (customerId: string, formdata: FormData) => {
        try {
            const response = await httpClient.post(`customers/${customerId}/documents`, formdata);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not upload document for customer with id: ${customerId}`,
                );
            }

            const data = await response.json();
            return {
                documentId: data.documentId,
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    downloadDocumentById: async (customerId: string, documentId: DocumentModel['documentId']) => {
        try {
            const response = await httpClient.get(`customers/${customerId}/documents/${documentId}`);

            if (!response.ok) {
                throw new Error(`Response status: ${response.status} - Could not get document with id ${documentId}`);
            }

            const data = await response.blob();
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    removeDocument: async (customerId: string, documentId: DocumentModel['documentId']) => {
        try {
            const response = await httpClient.delete(`customers/${customerId}/documents/${documentId}`);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not delete document with id ${documentId}`,
                );
            }

            return { documentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getCommentsForId: async (customerId: string) => {
        try {
            const response = await httpClient.get(`customers/${customerId}/comments`);

            if (!response.ok) {
                throw new Error(`Could not get comments for customer with id: ${customerId}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
    addComment: async (payload: CustomerCommentPayload) => {
        try {
            const { customerId, comment } = payload;
            const response = await httpClient.post(`customers/${customerId}/comments`, JSON.stringify({ comment }));

            if (!response.ok) {
                throw new Error(`Could not add comment for customer id: ${customerId} with payload: ${comment}`);
            }

            const data = await response.json();
            return { commentId: data.commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    removeComment: async (payload: CustomerCommentPayload) => {
        try {
            const { customerId, commentId } = payload;
            const response = await httpClient.delete(`customers/${customerId}/comments/${commentId}`);

            if (!response.ok) {
                throw new Error(`Could not delete comment with id: ${commentId} for customer with id: ${customerId}`);
            }

            return { commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    editComment: async (payload: CustomerCommentPayload) => {
        try {
            const { comment, commentId, customerId } = payload;
            const response = await httpClient.put(
                `customers/${customerId}/comments/${commentId}`,
                JSON.stringify({ comment }),
            );

            if (!response.ok) {
                throw new Error(`Could not edit comment with id: ${commentId} for customer with id: ${customerId}`);
            }

            return { commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    replyToComment: async (payload: CustomerCommentPayload) => {
        try {
            const { customerId, comment, parentId } = payload;
            const response = await httpClient.post(
                `customers/${customerId}/comments`,
                JSON.stringify({ parentId, comment }),
            );

            if (!response.ok) {
                throw new Error(`Could not reply to comment with id: ${parentId} for customer with id: ${customerId}`);
            }

            const data = await response.json();

            return { commentId: data.commentId };
        } catch (error) {
            console.error(error);
            return null;
        }
    },
});
