import { createPaginatedResponseSchema } from 'context/api/utils/paginated-response';
import { z } from 'zod';

const emptyPaginatedResponseSchema = createPaginatedResponseSchema(z.object({}));
type EmptyPaginatedResponse = z.infer<typeof emptyPaginatedResponseSchema>;

export const emptyPaginatedResult: EmptyPaginatedResponse = {
    list: [],
    total: 0,
};
