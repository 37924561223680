import { HttpClient } from 'aurelia-fetch-client';

import { UserPaginatedResponse } from 'schema/user/user-schema';

import { emptyPaginatedResult } from './utils/empty-paginated-result';

export type UserApi = {
    getAll(): Promise<UserPaginatedResponse>;
};

export const createUserApi = (httpClient: HttpClient): UserApi => ({
    getAll: async () => {
        try {
            const response = await httpClient.get('users');

            if (!response.ok) {
                throw new Error(`Could not get users`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return emptyPaginatedResult;
        }
    },
});
