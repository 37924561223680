import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { getFormattedTime } from 'utils/datetime-formatter';
import { type TMSResponse } from '../../response-types/base-response';

import '../../pli/pli-card';
import '../../pli/pli-pagination';
import '../../pli/pli-status-label';
import '../../pli/pli-user-bubble-list';
import '../../pli/pli-enum-filter';

import { clientContext, HttpClient } from 'context/client-context';
import { StorageOrderController } from 'controllers/storage-order-controller';
import { defineHeaderItems } from '../../pli/pli-table';
import { PliStatusLabelVariant } from '../../pli/pli-status-label';
import { PliUserBubbleProps } from '../../pli/pli-user-bubble';
import { ifDefined } from 'lit/directives/if-defined.js';
import { baseStyles, gridStyles } from 'pli';
import { urlParamsBuilder } from 'utils/urlParamsBuilder';
import '../../pli/pli-pager';

type AlertResponse = TMSResponse<Alert>;
type Alert = {
    alertId: string;
    status: PliStatusLabelVariant;
    customer: Customer;
    rule: Rule;
    created: Date;
    assigned: PliUserBubbleProps[];
};
type Customer = {
    customerId: string;
    name: string;
};
type Rule = {
    name: string;
};
type QueryPayload = {
    field: string | null;
    order: string | null;
    page: number;
    statuses: string[];
};
type StatusFilterItem = {
    label: string;
    value: string;
};

@customElement('alert-overview')
class AlertOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client: HttpClient | undefined;

    static styles = [baseStyles, gridStyles];

    @state()
    _page = 1;
    @state()
    _total = 0;
    @state()
    _selectedStatusFilterItems: StatusFilterItem[] = [];

    sortOrderKey: string = 'alert-list-order';

    headerItems = defineHeaderItems({
        Id: {
            sortField: 'AlertId',
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 2,
        },
        Customer: {
            sortField: null,
            columnSpan: 2,
        },
        Status: {
            sortField: null,
            columnSpan: 1,
        },
        Created: {
            sortField: 'Created',
            columnSpan: 1,
        },
        Assigned: {
            sortField: null,
            columnSpan: 1,
        },
    });

    statusFilterPlaceholder = 'Status';
    statusFilterItems: StatusFilterItem[] = [
        {
            label: 'New',
            value: 'New',
        },
        {
            label: 'Opened',
            value: 'Opened',
        },
        {
            label: 'Escalated',
            value: 'Escalated',
        },
        {
            label: 'Closed',
            value: 'Closed',
        },
        {
            label: 'FalsePositive',
            value: 'FalsePositive',
        },
    ];

    items: Alert[] = [];

    controller = new StorageOrderController(this, this.sortOrderKey);

    connectedCallback(): void {
        super.connectedCallback();
        this._selectedStatusFilterItems = this.statusFilterItems;
    }

    private _task = new Task(this, {
        task: async ([page, field, order, selectedStatuses]) => {
            const query = this.buildQuery({ page, field, order, statuses: selectedStatuses.map((x) => x.value) });
            const url = `alerts?${query}`;

            const response = await this.client?.get(url);
            const data = (await response?.json()) as AlertResponse;

            this._total = data.total;
            this.items = data.list;

            return data;
        },
        args: () =>
            [this._page, this.controller.field, this.controller.order, this._selectedStatusFilterItems] as const,
    });

    buildQuery(payload: QueryPayload): string {
        const { field, order, page, statuses } = payload;
        return urlParamsBuilder({ statuses, page: { no: page }, sort: { field, order } });
    }

    private _renderItem(alert: Alert): TemplateResult {
        return html`
            <tr>
                <td>
                    <a
                        data-link="navigate"
                        href="customers/${alert.customer.customerId}/alerts/${alert.alertId}/#investigations"
                    >
                        <strong>${alert.alertId}</strong>
                    </a>
                </td>
                <td>${alert.rule.name}</td>
                <td>${alert.customer.name}</td>
                <td><pli-status-label variant="${alert.status}"></pli-status-label></td>
                <td>${getFormattedTime(alert.created)}</td>
                <td>
                    <pli-user-bubble-list .items="${alert.assigned}"></pli-user-bubble-list>
                </td>
            </tr>
        `;
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sort(event: CustomEvent) {
        this.controller.field = event.detail.field;
        this.controller.order = event.detail.order;
    }

    private onFilterChange(event: any): void {
        this._selectedStatusFilterItems = event.detail.value as StatusFilterItem[];
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-4">
                            <pli-enum-filter
                                @change="${this.onFilterChange}"
                                placeholder="${ifDefined(this.statusFilterPlaceholder)}"
                                .items="${this.statusFilterItems}"
                            ></pli-enum-filter>
                        </div>
                    </div>
                </pli-card>
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2">Alerts</pli-text>
                        </div>
                    </div>
                    <pli-pager
                        .items="${this.items}"
                        page="${this._page}"
                        total="${this._total}"
                        @page-update="${this._paginationHandler}"
                        ><pli-table
                            @sort="${this._sort}"
                            .headerItems="${this.headerItems}"
                            .items="${this.items}"
                            .renderTemplate="${this._renderItem}"
                            sortOrderKey="${this.sortOrderKey}"
                        >
                        </pli-table
                    ></pli-pager>
                </pli-card>
            </div>
        `;
    }
}
